<template>
  <b-container class="min-vh-100 d-flex flex-column">
    <b-row>
      <b-col cols="1"/>
      <b-col cols="9">
        <h1 style="margin-top: 4.25rem; margin-bottom: 2rem;">Fahrt pausiert</h1>
      </b-col>
      <b-col cols="1"/>
    </b-row>
    <b-row>
      <b-col cols="1"/>
      <b-col cols="10">
        <p>Du kannst das Pausieren jeder Zeit beenden.</p> 
        <p>Denke an deine Mitfahrer, die vielleicht auch gerade ein Bike gebrauchen könnten.</p>
      </b-col>
    </b-row>
     <b-row>
      <b-col cols="1" />
      <b-col cols="10">
        <b-progress :value="18" :max="30"/>
        <p style="color: var(--visual-information-color)"><br>12 Minuten übrig</p>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row>
      <b-col cols="1"/>
        <b-row style="text-align: center;">
      <b-col cols="2"/>
      <b-col cols="8">
        <img src="@/assets/illustrations/pause.svg" class="illustration" alt="biker" style="width: 100%; height: 100%; margin-top: 0.5rem; margin-bottom: 2rem;"/>
        </b-col>
      <b-col cols="2"/>
    </b-row>
    </b-row>
    <b-row style="text-align: center;" class="flex-grow-1">
      <b-col cols="1"/>
      <b-col cols="10" class="justify-content-center align-self-center">
        <b-button variant="secondary" class="buttonSpacing">Sperren</b-button>
        <b-button variant="primary" class="buttonSpacing" @click="$router.push('/driving')">Weiterfahren</b-button>
      </b-col>
      <b-col cols="1"/>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'PauseInfo',
  data() {
    return {
    }
  },
  components: {
    }
}
</script>

<style scoped>

</style>